body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #292C2E;
  touch-action: pan-x pan-y;
}

.adm-nav-bar {
  color: #fff;
  --height: 60px;
  position: absolute;
  left: 0;
  right: 0;

  .adm-button:not(.adm-button-default).adm-button-fill-none {
    --text-color: #fff;
  }
}

.adm-error-block {
  padding-left: 32px;
  padding-right: 32px;

  .adm-error-block-description,
  .adm-error-block-description-title {
    color: #fff;
  }
}

.spin-full-container {
  .adm-spin-loading {
    margin: 160px auto;
  }
}

.header-logo-container {
  text-align: center;
}

.header {
  color: #666;
  text-align: center;
  pointer-events: none;

  .adm-image {
    height: 64px;
    opacity: .1;
    margin-top: 4px;
    margin-bottom: 16px;
  }
}

.adm-card {
  padding: 8px 16px;
  margin: 12px 24px;

  .adm-card-header {
    text-align: center;

    .adm-card-header-title {
      width: 100%;
    }
  }

  .adm-card-body {
    font-size: 15px;
  }

  .organization-name {
    font-size: 16px;
  }

  .charger-name {
    margin-top: 24px;
    margin-bottom: 16px;

    > .adm-tag {
      font-size: 28px;
      padding: 8px 12px;
      white-space: normal;
      color: #2F4E5A;
      width: 100%;
    }
  }

  .agreement-container {
    margin: 0 40px;
    justify-content: center;
    font-size: 12px;
    align-items: baseline;
  }
}

.footer {
  padding: 16px 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.charging-progress-container {
  position: relative;
  text-align: center;
  margin: auto;
  width: 200px;
  height: 200px;

  .charging-progress-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    overflow: hidden;
    transition: height 1s;

    > svg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .charging-progress-overlay-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 70px;
    margin: auto;

    > .charging-progress-overlay-content-title {
      font-size: 16px;
      line-height: 1;
    }

    > .charging-progress-overlay-content-duration {
      font-size: 36px;
      font-weight: bold;
    }
  }
}

.charging-bottom {
  text-align: center;

  > .charging-bottom-esimated-time {
    font-size: 20px;
    margin-bottom: 4px;
  }

  > .charging-bottom-title {
    font-size: 12px;
  }
}

